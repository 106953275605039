<template>
  <div class="main-box" style="position: relative;">
    <div class="mg-bt-10" style="position: fixed; top: 80px; left: 295px; z-index: 10;">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        row-key="id"
        style="width: 100%; margin-top: 30px;"
        size="mini"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="authkey"
          label="模块键值">
      </el-table-column>
      <el-table-column
          prop="ctype"
          label="类型">
        <template slot-scope="scope">
          {{scope.row.ctype==1?'菜单':'功能'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="path"
          label="页面地址">
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="父级菜单" :label-width="formLabelWidth">
          <el-select v-model="form.pid" placeholder="请选择菜单">
            <el-option :label="item.title" :value="item.id" v-bind:key="index" v-for="(item, index) in selectData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模块键值" :label-width="formLabelWidth">
          <el-input v-model="form.authkey" autocomplete="off" placeholder="请输入键值"></el-input>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-radio-group v-model="form.ctype">
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">功能</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="页面地址" :label-width="formLabelWidth" v-if="form.ctype==2">
          <el-input v-model="form.path" autocomplete="off" placeholder="请填写页面地址"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off" placeholder="请填写排序"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加菜单',
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: 0,
        title: '',
        ctype: 1,
        path: '',
        authkey: '',
        ordering: ''
      },
      formLabelWidth: '120px',
      tableData: [],
      selectData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var _this = this
      this.showLoading()
      this.selectData = []
      this.$api.merchant.functionMenuIndex({}, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          this.tableData = res.data
          if (this.tableData) {
            var tmpData = JSON.parse(JSON.stringify(this.tableData))
            tmpData.forEach(function (item) {
              _this.selectData.push(item)
              if (item.children && item.children.length > 0) {
                item.children.forEach(function (child) {
                  child.title = '|-- ' + child.title
                  _this.selectData.push(child)
                })
              }
            })
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      var that = this
      this.$api.merchant.functionMenuDetail({id: item.id}, function (res) {
        if (res.errcode == 0) {
          that.form = res.data
          if (that.form.pid == 0) {
            that.form.pid = ''
          }
          that.dialogFormVisible = true
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.form.id = 0
      this.form.pid = ''
      this.form.title = ''
      this.form.authkey = ''
      this.form.type = 1
      this.form.path = ''
      this.form.ordering = ''
      this.dialogFormVisible = true
    },
    saveMenu() {
      var that = this
      var param = this.form
      if (this.form.id == 0) {
        this.$api.merchant.functionMenuAdd(param, function (res) {
          if (res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      } else {
        this.$api.merchant.functionMenuEdit(param, function (res) {
          if (res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.merchant.functionMenuDelete({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
</style>
